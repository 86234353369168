// src/services/api.js
import axios from 'axios';

const api = axios.create({
  baseURL:process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  }

});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});


export const login = async (email, password) => {
  try { 
    const response = await api.post('/users/login', { email, password });
    return {data:response.data,token:response.headers.authorization};
  } catch (error) {
    throw error;
  }
};

export const getClients = async () => {
  try { 
    const response = await api.get('/clients');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createClient = async (data) => {
  try { 
    const response = await api.post('/clients', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getClient = async (clientId) => {
  try { 
    const response = await api.get(`/clients/${clientId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateClient = async (clientId, clientData) => {
  try { 
    const response = await api.put(`/clients/${clientId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const addClientCredit = async (clientId, creditData) => {
  
  try { 
    const response = await api.put(`clients/${clientId}/credits`,creditData);
    return response.data;
  } catch (error) {
    throw error;
  }

}

export default api;
